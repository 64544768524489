<template>
    <div>
        <b-row class="match-height">
            <b-col lg="12">
                <b-card class="px-2">
                    <b-row>
                        <b-col lg="6" v-b-toggle.collapse-1 class="d-flex align-items-center mb-2" @click="collapsePaymentMethods">
                            <div>
                                <b-card-title>
                                    Payment methods
                                </b-card-title>
                                <b-card-sub-title>Add new payment method</b-card-sub-title>
                            </div>
                            <div class="ml-4">
                                <feather-icon :icon="collapseIcon" size="24" />
                            </div>
                        </b-col>
                    </b-row>

                    <b-collapse
                        id="collapse-1"
                        class="mt-2"
                    >
                        <b-row>
                            <b-col lg="6">
                                <div>
                                    <label>Card Holder Name</label>
                                    <input id="card-holder-name" type="text" v-model="holderName" class="form-control mb-2">

                                    <label>Card</label>
                                    <div id="card-element"></div>

                                    <button class="btn btn-primary mt-3" @click="submitPaymentMethod()" :disabled="isDisabled">
                                        Add Payment Method
                                    </button>
                                </div>
                            </b-col>
                        </b-row>

                        <b-row v-if="paymentMethodsLoadStatus == 2 && paymentMethods.length > 0">
                            <b-col lg="12">
                                <div class="mt-5">
                                    <b-card-sub-title class="mb-2">My payment methods</b-card-sub-title>
                                </div>

                                <div v-show="paymentMethodsLoadStatus == 2 && paymentMethods.length == 0">
                                    <p class="text-center">No payment method on file, please add a payment method.</p>
                                </div>
                                <div v-show="paymentMethodsLoadStatus == 2 && paymentMethods.length > 0">
                                    <!-- Table -->
                                    <b-table
                                    striped
                                    hover
                                    responsive
                                    class="position-relative"
                                    :per-page="perPage"
                                    :current-page="currentPage"
                                    :items="paymentMethods"
                                    :fields="fields"
                                    :sort-by.sync="sortBy"
                                    :sort-desc.sync="sortDesc"
                                    :sort-direction="sortDirection"
                                    >
                                        <!-- Column: Brand -->
                                        <template #cell(brand)="paymentMethods">
                                            <span class="font-weight-bold d-block text-capitalize">
                                                {{ paymentMethods.item.brand }}
                                            </span>
                                        </template>

                                        <!-- Column: Ending in -->
                                        <template #cell(last_four)="paymentMethods">
                                            <span>{{
                                                paymentMethods.item.last_four
                                            }}</span>
                                        </template>

                                        <!-- Column: Expiration -->
                                        <template #cell(expiration)="paymentMethods">
                                            <div class="text-nowrap">
                                            <span class="align-text-top text-capitalize">{{
                                                paymentMethods.item.exp_month}} / {{ paymentMethods.item.exp_year
                                            }}</span>
                                            </div>
                                        </template>
                                        
                                        <!-- Column: Actions -->
                                        <template #cell(action)="paymentMethods">
                                            <b-badge variant="light-danger" @click="removePaymentMethod(paymentMethods.item.id)" v-b-tooltip.hover.top="'Remove method'">
                                                <feather-icon icon="Trash2Icon" />
                                            </b-badge>
                                        </template>
                                    </b-table>

                                    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
                                        <!-- pagination -->
                                        <div>
                                            <b-pagination
                                            v-model="currentPage"
                                            :total-rows="totalRows"
                                            :per-page="perPage"
                                            first-number
                                            last-number
                                            prev-class="prev-item"
                                            next-class="next-item"
                                            class="mb-0"
                                            >
                                            <template #prev-text>
                                                <feather-icon icon="ChevronLeftIcon" size="18" />
                                            </template>
                                            <template #next-text>
                                                <feather-icon icon="ChevronRightIcon" size="18" />
                                            </template>
                                            </b-pagination>
                                        </div>
                                    </b-card-body>
                                </div>
                            </b-col>
                        </b-row>
                    </b-collapse>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import store from "@/store/index";
import EventBus from '../../main.js';
import { BOverlay, BCard, BCardTitle, BCardSubTitle, BRow, BCol, BCollapse, BButton, VBToggle, BBadge, BTable, BCardBody, BPagination, VBTooltip } from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
    components: {
        BOverlay,
        BCard,
        BCardTitle, 
        BCardSubTitle,
        BRow,
        BCol,
        BBadge,
        BCollapse,
        BButton,
        BTable,
        BCardBody,
        BPagination,
    },
    directives: {
        'b-tooltip': VBTooltip,
        'b-toggle': VBToggle,
    },
    data() {
        return {
            icon: "ChevronDownIcon",
            stripeAPIToken: store.state.app.stripeAPIToken,

            stripe: '',
            elements: '',
            card: '',

            intentToken: '',

            holderName: '',
            addPaymentStatus: 0,
            addPaymentStatusError: '',

            paymentMethods: [],
            paymentMethodsLoadStatus: 0,

            disabled: false,

            //Table
            searchMethod: "",
            perPage: 5,
            pageOptions: [3, 5, 10],
            totalRows: 1,
            currentPage: 1,
            sortBy: "",
            sortDesc: false,
            sortDirection: "asc",
            fields: [
                { key: "brand", label: "Card Type" },
                { key: "last_four", label: "Ending in", class: "text-center" },
                { key: "expiration", label: "Expiry date" },
                { key: "action", label: "Action", class: "text-center text-nowrap" }
            ],
        }
    },
    computed: {
        isDisabled() {
            if(this.holderName.length == 0 || this.disabled == true) {
                return true
            } else {
                return false
            }
        },
        collapseIcon() {
            if(this.icon == "ChevronDownIcon") {
                return "ChevronDownIcon"
            } else {
                return "ChevronUpIcon"
            }
        }
    },
    methods: {
        collapsePaymentMethods() {
            if(this.icon == 'ChevronDownIcon') {
                this.icon = 'ChevronUpIcon'
            } else {
                this.icon = 'ChevronDownIcon'
            }
        },

        // Includes Stripe.js dynamically
        includeStripe( URL, callback ) {
            var documentTag = document, tag = 'script',
                object = documentTag.createElement(tag),
                scriptTag = documentTag.getElementsByTagName(tag)[0];
            object.src = '//' + URL;
            if (callback) { object.addEventListener('load', function (e) { callback(null, e); }, false); }
            scriptTag.parentNode.insertBefore(object, scriptTag);
        },

        /*
            Configures Stripe by setting up the elements and 
            creating the card element.
        */
        configureStripe() {
            this.stripe = Stripe( this.stripeAPIToken );

            this.elements = this.stripe.elements();
            this.card = this.elements.create('card');

            this.card.mount('#card-element');
        },

        // Loads the payment intent key for the user to pay.
        loadIntent(){
            this.$http.get("/api/auth/payment/setup-intent")
                .then( function( response ){
                    this.intentToken = response.data;
                }.bind(this));
        },

        /*
            Uses the intent to submit a payment method
            to Stripe. Upon success, we save the payment
            method to our system to be used.
        */
        submitPaymentMethod() {
            this.disabled = true
            this.addPaymentStatus = 1;

            this.stripe.confirmCardSetup(
                this.intentToken.client_secret, {
                    payment_method: {
                        card: this.card,
                        billing_details: {
                            name: this.holderName
                        }
                    }
                }
            ).then((result) => {
                if (result.error) {
                    this.addPaymentStatus = 3;
                    this.addPaymentStatusError = result.error.message;

                    this.disabled = false

                    // Show toast
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: this.addPaymentStatusError,
                            icon: "AlertCircleIcon",
                            variant: "danger",
                        },
                    });
                } else {
                    this.savePaymentMethod( result.setupIntent.payment_method );
                    this.addPaymentStatus = 2;
                    this.card.clear();
                    this.holderName = '';

                    this.disabled = false

                    // Show toast
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "Successfully added payment method",
                            icon: "CheckSquareIcon",
                            variant: "success",
                        },
                    });
                }
            })
            .catch((err) => {
                console.log(err)

                this.disabled = false

                // Show toast
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: "Something went wrong. Please try again",
                        icon: "AlertCircleIcon",
                        variant: "danger",
                    },
                });
            })
        },

        // Saves the payment method for the user and re-loads the payment methods.
        savePaymentMethod(method) {
            this.$http.post("/api/auth/payment/payment-method", {                    
                payment_method: method
            }).then( function(){
                this.loadPaymentMethods();
                
                // Refresh payments method in Subscriptions component
                EventBus.$emit('refreshPaymentMethods')
            }.bind(this));
        },
        
        // Loads all of the payment methods for the user.
        loadPaymentMethods() {
            this.$store.dispatch("subscriptionStoreModule/setOverlay", true)
 
            this.paymentMethodsLoadStatus = 1;

            this.$http.get('/api/auth/payment/payment-methods')
                .then((response) => {
                    if(response != undefined) {

                        this.paymentMethods = response.data;
                        
                        this.paymentMethodsLoadStatus = 2;

                        this.$store.dispatch("subscriptionStoreModule/setOverlay", false)

                        // this.setDefaultPaymentMethod();
                    }
                })
                .catch((err) => {
                    this.paymentMethodsLoadStatus = 2;
                    console.log(err)

                    // Show toast
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "Something went wrong. Please try again",
                            icon: "AlertCircleIcon",
                            variant: "danger",
                        },
                    });
                })
        },

        // Remove payment method
        removePaymentMethod(paymentID) {
            this.$bvModal
                .msgBoxConfirm('This payment method will be removed!', {
                title: 'Are your sure?',
                size: 'sm',
                okVariant: 'primary',
                okTitle: 'Yes',
                cancelTitle: 'No',
                cancelVariant: 'outline-secondary',
                hideHeaderClose: false,
                centered: true,
            }).then(value => {
                if(value == true) {
                    // Show overlay
                    this.$store.dispatch("subscriptionStoreModule/setOverlay", true)

                    this.$http.patch('/api/auth/payment/payment-method', {
                        id: paymentID
                    })
                    .then((response) => {
                        // Refresh payment methods in Subscriptions component 
                        EventBus.$emit('refreshPaymentMethods')

                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "Payment method was successfully removed!",
                                icon: "CheckSquareIcon",
                                variant: "success",
                            },
                        });

                        this.loadPaymentMethods();                
                    }).catch((error) => {
                        console.log(error)

                        let errorMsg = "There was an error removing payment method"
                        if(error.response.data.message != null) {
                            errorMsg = error.response.data.message
                        }

                        this.$store.dispatch("subscriptionStoreModule/setOverlay", false)

                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: errorMsg,
                                icon: "AlertCircleIcon",
                                variant: "danger",
                            },
                        });
                    });
                }
            })
        }
    },

    mounted(){
        this.includeStripe('js.stripe.com/v3/', function(){
            this.configureStripe();
        }.bind(this));

        this.loadIntent();

        this.loadPaymentMethods();
    },
    
    created() {
        // Set the initial number of items
        this.totalRows = this.paymentMethods.length;
    },
    watch:{
        data() {
            this.totalRows = this.paymentMethods.length
        },
    }

}
</script>
