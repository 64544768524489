<template>
    <div class="px-3">
        <div v-if="selectedPlan != null">
            <b-row>
                <b-col lg="12">
                    <label>Choose payment method</label>
                    <v-select
                        v-model="paymentMethodSelected"
                        :options="paymentMethods"
                        label="text"
                        placeholder="Select payment method"
                    />
                </b-col>
            </b-row>

            <div>
                <div v-if="isOnGracePeriod" class="mt-3">
                    <div>
                        <label>Grace period expires: {{moment(gracePeriodExpiration).format("ll")}}</label>
                    </div>
                    <b-button class="btn btn-primary mt-1" variant="primary" @click="updateSubscription" :disabled="isDisabled">
                        Resubscribe
                    </b-button>
                </div>
                <b-button v-else class="btn btn-primary mt-1 mb-3" variant="primary" @click="updateSubscription" :disabled="isDisabled">
                    Make Payment
                </b-button>
            </div>
        </div>

        <div v-else class="text-center">
            <p>Cancel subscription</p>
            <small>
                You are currently on the premium plan and get full access to all mental health checks and all recommended content to support your mental health. By selecting ‘Cancel subscription’ you will have restricted access to mental health checks and recommendations.
            </small>        
            <div class="text-center">
                <b-button  class="btn btn-primary mt-3 mb-4 mx-auto" variant="primary" @click="cancelSubscription">
                    Cancel subscription
                </b-button>
            </div>
        </div>

        
    </div>
</template>

<script>
import EventBus from '../../main.js';
import { BRow, BCol, BButton} from "bootstrap-vue";
import vSelect from 'vue-select'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import moment from 'moment'

export default {
    components: {
       BRow, BCol, BButton, vSelect
    },
    data() {
        return {
            moment: moment,
            show: true,
            paymentMethods: [],
            paymentMethodSelected: null,
        }
    },
    computed: {
        selectedPlan() {
            return this.$store.getters["subscriptionStoreModule/selectedPlan"]
        },
        isDisabled() {
            if(this.paymentMethodSelected == null) {
                return true
            } else {
                return false
            }
        },
        isOnGracePeriod() {
            return this.$store.getters["subscriptionStoreModule/isOnGracePeriod"]
        },
        gracePeriodExpiration() {
            return this.$store.getters["subscriptionStoreModule/gracePeriodExpiration"]
        }
    },
    methods: {
        // Loads all of the payment methods for the user.
        loadPaymentMethods(){
            this.$store.dispatch("subscriptionStoreModule/setOverlay", true)

            this.$http.get('/api/auth/payment/payment-methods')
                .then((response) => {
                    if(response != undefined) {

                        let methods = []

                        // Fill options in select
                        for(let i=0; i<response.data.length; i++) {
                            let brand = response.data[i].brand.charAt(0).toUpperCase() + response.data[i].brand.slice(1)

                            methods.push({ 'value': response.data[i].id, 'text': brand + ' (Ending in: '+response.data[i].last_four+') ' + ' - Exp: ' + response.data[i].exp_month + '/' + response.data[i].exp_year })
                        }

                        this.paymentMethods = methods;

                        this.$store.dispatch("subscriptionStoreModule/setOverlay", false)
                    }
                })
                .catch((err) => {
                    console.log(err)

                    this.$store.dispatch("subscriptionStoreModule/setOverlay", false)

                    // Show toast
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "Something went wrong. Please try again",
                            icon: "AlertCircleIcon",
                            variant: "danger",
                        },
                    });
                })
        },

        // Update subscription
        updateSubscription() {
            this.$store.dispatch("subscriptionStoreModule/setOverlay", true)
            this.disabled = true

            // If user choose free plan 
            if(this.selectedPlan == null) {
                this.cancelSubscription()
            } else {
                let plan_data = {
                    plan: this.selectedPlan,
                    payment: this.paymentMethodSelected.value
                };
    
                //plan_data.plan_name = "Mental Health Check Access" // LIVE                
                plan_data.plan_name = "MHC Premium Plan" // LOCALHOST OR DEV
    
                this.$http.patch('/api/auth/payment/subscribe', plan_data)
                .then((response) => {
                    this.$store.dispatch("subscriptionStoreModule/setSubscribeDetails", response.data)

                    // Reset form
                    this.currentSubscriptionPrice = this.selectedPlan
                    this.paymentMethodSelected = null
    
                    this.$store.dispatch("subscriptionStoreModule/setOverlay", false)
                    this.disabled = false

                    // Refresh Pricing modal
                    EventBus.$emit("getCurrentSubscriptionPlan")
    
                    // Show toast
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "Successfully changed subscription plan",
                            icon: "CheckSquareIcon",
                            variant: "success",
                        },
                    });

                    // Emit event to change show status of upgrade button in Navbar.vue
                    EventBus.$emit('changeStatusForUpgradeNavButton')

                    // Increment current step and call event next sub step
                    EventBus.$emit("nextSubscriptionStep", 3)
                })
                .catch((err) => {
                    this.$store.dispatch("subscriptionStoreModule/setOverlay", false)
                    this.disabled = false

                    let errorMsg = "Something went wrong. Please try again"
                    if(err.response != undefined) {
                        if(err.response.data.message != undefined) {
                            errorMsg = err.response.data.message
                        }
                    }
    
                    // Show toast
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: errorMsg,
                            icon: "AlertCircleIcon",
                            variant: "danger",
                        },
                    });
                })
            }
        },

        cancelSubscription() {
            this.$bvModal
                .msgBoxConfirm("You are currently on the premium plan and get full access to all mental health checks and all recommended content to support your mental health. By selecting ‘Cancel subscription’ you will have restricted access to mental health checks and recommendations", {
                title: 'Are your sure?',
                size: 'sm',
                okVariant: 'primary',
                okTitle: 'Yes',
                cancelTitle: 'No',
                cancelVariant: 'outline-secondary',
                hideHeaderClose: false,
                centered: true,
            }).then(value => {        
                if(value == true) {
                    this.$http.get('/api/auth/payment/unsubscribe')
                    .then((response) => {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "Subscription was successfully canceled",
                                icon: "CheckSquareIcon",
                                variant: "success",
                            },
                        });

                        this.$store.dispatch("subscriptionStoreModule/setOverlay", false)
                        this.disabled = false

                        this.currentSubscriptionPrice = this.selectedPlan
                        this.paymentMethodSelected = null

                        this.loadPaymentMethods();   
                        
                        // Refresh Pricing modal
                        EventBus.$emit("getCurrentSubscriptionPlan")

                        // Increment current step and call event next sub step
                        EventBus.$emit("nextSubscriptionStep", 3)

                    }).catch((error) => {
                        this.$store.dispatch("subscriptionStoreModule/setOverlay", false)
                        this.disabled = false

                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "There was an error canceling subscription",
                                icon: "AlertCircleIcon",
                                variant: "danger",
                            },
                        });
                    });
                }
            })
        }
    },
    mounted() {
        this.loadPaymentMethods()

        EventBus.$on('refreshPaymentMethods', () => {
            this.loadPaymentMethods()
        });
    }
}
</script>