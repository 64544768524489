<template>
    <div>
        <b-card>
            <p class="cursor-pointer font-weight-normal" @click="previousStep" v-if="currentStep > 1 && currentStep < 3">Back</p>

            <div class="mx-auto w-100">
                <div class="mt-2 mb-4 mx-auto payment-wrapper">
                    <ol class="sub-stepper">
                        <li class="sub-stepper__item" v-bind:class="{ activated: step.active, active: step.step == currentStep }" v-for="(step, index) in steps" :key="index" :disabled="!step.active">
                            <small>{{ step.name }}</small>
                        </li>
                    </ol>
                </div>

                <div v-if="currentStep == 1" class="mx-auto payment-wrapper">
                    <subscriptions />
                </div>

                <div v-if="currentStep == 2" class="mx-auto payment-wrapper">
                    <b-overlay :show="showOverlay" rounded="sm">
                       <div class="text-center mb-3 pt-2">
                           <b-card-title>
                               Payment
                           </b-card-title>
                       </div>
                       
                       <b-row>
                           <b-col md="12" v-if="isOnGracePeriod == 0 && firstPaymentDueToday != 0">
                               <div class="px-3 mb-4">
                                   <p>First payment due today</p>
                                   <h2 class="pricing-basic-value font-weight-bolder color-mhc-dark">£{{parseInt(firstPaymentDueToday).toFixed(2)}}</h2>
                               </div>
                           </b-col>

                           <b-col md="12" v-if="selectedPlan != null">
                               <payment-methods />
                           </b-col>
                       </b-row>

                       <b-row>
                           <b-col md="12">
                               <choose-payment-methods />
                           </b-col>
                       </b-row>
                    </b-overlay>
                </div>

                <div v-if="currentStep == 3" class="m-auto payment-wrapper pb-3">
                    <div class="payment-received p-3">
                        <div class="mt-1 pb-5 text-center">
                            <img src="/checked-circle.png" class="mb-3">
                            <p v-if="selectedPlan == null">SUBSCRIPTION SUCCESSFULLY CANCELED</p>
                            <p v-else>PAYMENT RECEIVED</p>
                        </div>

                        <div class="mt-5" v-if="selectedPlan != null">
                            <div class="d-flex justify-content-between align-items-center my-1">
                                <div class="font-weight-bold">Plan Type</div>
                                <div class="font-weight-bold">{{subscribeDetails.name}}</div>
                            </div>
                            <div class="d-flex justify-content-between align-items-center my-1">
                                <div class="font-weight-bold">Total Payment</div>
                                <div class="font-weight-bolder color-mhc-dark">£{{parseInt(subscribeDetails.price).toFixed(2)}}</div>
                            </div>
                            <div class="d-flex justify-content-between align-items-center my-1">
                                <div class="font-weight-bold">Payment Method</div>
                                <div class="color-gray"><small>xxxx xxxx xxxx</small> {{subscribeDetails.pm_last_four}}</div>
                            </div>
                            <div class="d-flex justify-content-between align-items-center my-1">
                                <div class="font-weight-bold">Payment Date</div>
                                <div class="color-gray">{{subscribeDetails.date}}</div>
                            </div>
                        </div>

                        <div class="mt-3 text-center">
                            <b-button variant="primary" @click="openMyHub">Continue</b-button>
                        </div>
                    </div>
                </div>

            </div>
        </b-card>
    </div>
</template>

<script>
import EventBus from '../../main.js';
import { BCard, BCardTitle, BRow, BCol, BOverlay, BButton } from "bootstrap-vue";
import Subscriptions from '../../components/payment/Subscriptions.vue'
import PaymentMethods from '../../components/payment/PaymentMethods.vue'
import ChoosePaymentMethods from '../../components/payment/ChoosePaymentMethods.vue'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
    components: {
        BCard,
        BCardTitle, 
        BRow,
        BCol,
        BOverlay,
        BButton,
        Subscriptions,
        PaymentMethods,
        ChoosePaymentMethods
    },
    data() {
        return {
            steps: [
                {
                    step: 1,
                    name: 'Step 1',
                    active: true
                },
                {
                    step: 2,
                    name: 'Step 2',
                    active: false
                },
                {
                    step: 3,
                    name: 'Step 3',
                    active: false
                },
            ],
            firstPaymentDueToday: 0
        }
    },
    computed: {
        showOverlay() {
            return this.$store.getters["subscriptionStoreModule/overlay"]
        },
        currentStep() {
            return this.$store.getters["subscriptionStoreModule/currentStep"]
        },
        selectedPlan() {
            return this.$store.getters["subscriptionStoreModule/selectedPlan"]
        },
        subscribeDetails() {
            return this.$store.getters["subscriptionStoreModule/subscribeDetails"]
        },
        fullPackagePrice() {
            return this.$store.getters["app/fullPackagePrice"]
        },
        isOnGracePeriod() {
            return this.$store.getters["subscriptionStoreModule/isOnGracePeriod"]
        },
    },
    methods: {
        changeCurrentStep(step) {
            if(step.active) {
                this.$store.dispatch("subscriptionStoreModule/setCurrentStep", step.step)
            }
        },
        nextStep(step) {
            if(this.currentStep < this.steps.length) {
                //this.$store.dispatch("subscriptionStoreModule/incrementCurrentStep")
                this.$store.dispatch("subscriptionStoreModule/setCurrentStep", step)
            }
            
            // Change steps status (active/not active)
            for(let i=0; i<this.steps.length; i++) {
                if(this.steps[i].step <= this.currentStep) {
                    this.steps[i].active = true
                }
            }
        },
        previousStep() {
            if(this.currentStep > 1) {
                this.$store.dispatch("subscriptionStoreModule/decrementCurrentStep")
            }
        },
        openMyHub() {
            this.$router.push({name: "my-hub"});
        },
        getFirstPaymentDueToday() {
            this.$http.get("/api/auth/payment/first-payment-due-today")
                .then((res) => {
                    if(res != undefined) {
                        this.firstPaymentDueToday = res.data.price
                    }
                })
                .catch((err) => {
                    console.log(err)

                    // Show toast
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "Something went wrong. Please try again",
                            icon: "AlertCircleIcon",
                            variant: "danger",
                        },
                    });
                })
        }
    },
    mounted() {
        // Reset current step to 1
        this.$store.dispatch("subscriptionStoreModule/setCurrentStep", 1)

        // Get full package price
        this.$store.dispatch("app/getFullPackagePrice")

        // Check grace period
        this.$store.dispatch("subscriptionStoreModule/setGracePeriod")

        // Get first payment due today
        this.getFirstPaymentDueToday()

        EventBus.$on("nextSubscriptionStep", (step) => {
            this.nextStep(step)
        })
    }
}
</script>

<style>
    .payment-received {
        max-width: 100%;
        width: 400px;
        border: 5px solid var(--mhc-dark);
        border-radius: 20px;
        margin: auto;
    }

    .payment-wrapper {
        max-width: 100%;
        width: 1000px
    }
</style>