<template>
    <div>
        <b-overlay :show="show" rounded="sm">
            <b-row class="match-height">
                <b-col lg="12">
                        <div class="mx-auto pt-2">
                            <b-row>
                                <b-col offset-sm-2
                                sm="12"
                                md="12"
                                offset-lg="2"
                                class="mx-auto mx-0 px-0">
                                    <h2 class="mb-md-5 bg-white rounded py-2 color-mhc-dark text-center" style="transform: scale(1.01);">
                                        Simple, clear, accessible support
                                    </h2>
                                </b-col>
                            </b-row>
                        </div>

                        <div>
                            <b-row class="pricing-card">
                                <b-col class="mx-auto">
                                    <b-row class="justify-content-center">
                                        <b-col lg="5" class="mb-3 mb-md-0">
                                            <b-card
                                            align="left"
                                            class="py-2 rounded border"
                                            v-bind:class="{'bg-light-gray': selectedPlan == null}"
                                            >
                                                <h3>Always free</h3>
                                                <small>
                                                    Get 3 free mental health checks every month
                                                </small>

                                                <!-- annual plan -->
                                                <div class="annual-plan">
                                                    <div class="plan-price mt-2">
                                                    <sup class="font-medium-1 font-weight-bold color-mhc-dark">£ </sup>
                                                    <span class="pricing-basic-value font-weight-bolder color-mhc-dark">0</span>
                                                    <sub class="pricing-duration text-body font-medium-1 font-weight-bold">/month</sub>
                                                    </div>
                                                </div>
                                                <!--/ annual plan -->

                                                <!-- plan benefit -->
                                                <b-list-group class="list-group-circle text-left">
                                                    <b-list-group-item
                                                    >
                                                        3 free mental health checks per month
                                                    </b-list-group-item>
                                                </b-list-group>
                                                <!--/ plan benefit -->

                                                <!-- buttons -->
                                                <b-button
                                                    block
                                                    class="mt-2"
                                                    variant="outline-primary"
                                                    v-if="currentSubscriptionPrice == null"
                                                >
                                                    CURRENT PLAN
                                                </b-button>
                                                <b-button
                                                    block
                                                    class="mt-2"
                                                    variant="primary"
                                                    @click="storeSelectedPlan(null)"
                                                    v-else
                                                >
                                                    SELECT
                                                </b-button>
                                            </b-card>
                                        </b-col>
                                        
                                        <b-col lg="5">
                                            <div class="position-absolute d-flex align-items-center justify-content-center px-1" style="background-color: #ff00ff; z-index: 999; padding: 6px; top: -37px;">
                                                <h4 class="font-weight-bold text-white mx-0 px-0 mb-0">Best Value</h4>                                
                                            </div>
                                            <b-card
                                            align="left"
                                            class="py-2 rounded border"
                                            v-bind:class="{'bg-light-gray': selectedPlan == productFullPackage}"
                                            >
                                                <h3>Premium</h3>
                                                <small>Access to all features</small>

                                                <!-- annual plan -->
                                                <div class="annual-plan">
                                                    <div class="row">
                                                        <div class="col-12 col-md-4">
                                                            <div class="plan-price mt-2">
                                                                <sup class="font-medium-1 font-weight-bold color-mhc-dark">£ </sup>
                                                                <span class="pricing-basic-value font-weight-bolder color-mhc-dark" style="font-size: 4rem;">1</span>
                                                                <sub class="pricing-duration text-body font-medium-1 font-weight-bold">/month</sub>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-md-8 d-flex align-items-end">
                                                            <div>
                                                                <p class="mb-0">
                                                                    Try Premium free for 7-days
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="mt-1 pricing-duration text-body font-weight-bold">
                                                        Get unlimited access for just £1/pm for 6 months then £4.99. Cancel anytime.                                                        
                                                    </div>
                                                </div>
                                                <!--/ annual plan -->

                                                <!-- plan benefit -->
                                                <b-list-group class="list-group-circle text-left">
                                                    <b-list-group-item>
                                                        Unlimited Mental Health Checks
                                                    </b-list-group-item>
                                                </b-list-group>
                                                <b-list-group class="list-group-circle text-left">
                                                    <b-list-group-item>
                                                        Unlimited support access
                                                    </b-list-group-item>
                                                </b-list-group>
                                                <b-list-group class="list-group-circle text-left">
                                                    <b-list-group-item>
                                                        Full community access
                                                    </b-list-group-item>
                                                </b-list-group>
                                                <!--/ plan benefit -->

                                                <!-- buttons -->
                                                <b-button
                                                    block
                                                    class="mt-2"
                                                    variant="outline-primary"
                                                    @click="storeSelectedPlan(productFullPackage, 1)"
                                                    v-if="currentSubscriptionPrice == productFullPackage"
                                                >
                                                    CURRENT PLAN
                                                </b-button>
                                                <div v-else class="text-center">
                                                    <b-button
                                                        block
                                                        class="mt-2 mb-1"
                                                        variant="primary"
                                                        @click="storeSelectedPlan(productFullPackage)"
                                                        >
                                                        Start 7-day free trial
                                                    </b-button>
                                                    <label class="mb-0">Try Mental Health Check premium for free. No commitment. Cancel anytime. We'll remind you <strong>two days</strong> before your Mental Health Check Premium trial ends.</label>
                                                </div>                                                
                                            </b-card>
                                        </b-col>
                                    </b-row>
                                </b-col>
                            </b-row>
                        </div>
                </b-col>
            </b-row>
        </b-overlay>
    </div>
</template>

<script>
import store from "@/store/index";
import EventBus from '../../main.js';
import { BCard, BCardTitle, BCardSubTitle, BRow, BCol, BOverlay, BButton, BListGroup, BListGroupItem } from "bootstrap-vue";
import vSelect from 'vue-select'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
    components: {
        BCard,
        BCardTitle, 
        BCardSubTitle,
        BRow,
        BCol,
        BOverlay,
        BButton,
        BListGroup,
        BListGroupItem,
        vSelect,
    },
    data(){
        return {
            show: true,
            disabled: false,
            currentSubscriptionPlan: null,
            currentSubscriptionPrice: null,

            // Products
            productFullPackage: store.state.app.productFullPackage,
            fullPackagePrice: store.state.app.fullPackagePrice,
        }
    },
    computed: {
        selectedPlan() {
            return this.$store.getters["subscriptionStoreModule/selectedPlan"]
        }
    },
    methods: {
        // Get current subscription plan
        getCurrentSubscriptionPlan() {
            this.$http.get('/api/auth/payment/current-plan')
                .then((response) => {
                    if(response != undefined) {
                        this.currentSubscriptionPlan = response.data.subscriptions
                        if(this.currentSubscriptionPlan.length == 0) {
                            this.currentSubscriptionPrice = null
                        } else {
                            this.currentSubscriptionPrice = response.data.subscriptions[0].stripe_price
                        }

                        this.$store.dispatch("subscriptionStoreModule/setSelectedPlan", this.currentSubscriptionPrice)
                        this.show = false
                    }
                })
                .catch((err) => {
                    console.log(err)
                    this.show = false

                    // Show toast
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "Something went wrong. Please try again",
                            icon: "AlertCircleIcon",
                            variant: "danger",
                        },
                    });
                })
        },

        // Store selected plan
        storeSelectedPlan(value, isCurrentPlan = 0) {
            
            if(isCurrentPlan == 1) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: "You are already on this plan",
                        icon: "AlertCircleIcon",
                        variant: "info",
                    },
                });
            } else {
                this.$store.dispatch("subscriptionStoreModule/setSelectedPlan", value)
                EventBus.$emit('nextSubscriptionStep', 2)
            }
            
        },

    },
    mounted() {
        // Check grace period
        this.$store.dispatch("subscriptionStoreModule/setGracePeriod")

        this.getCurrentSubscriptionPlan()
    }
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-wizard.scss';
  @import '@core/scss/vue/libs/vue-select.scss';
</style>

<style scoped>
    .bg-light-gray {
        background-color: #f3f3f3;
    }

    .list-group-item {
        background-color: unset;
    }

    .list-group.list-group-circle .list-group-item:after {
        background-image: url(/check_circle.png);
        background-size: 20px;
    }

    .list-group.list-group-circle .list-group-item:after {
        height: 20px;
        width: 20px;
        margin-top: -6px;
    }
</style>
